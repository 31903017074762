<template>
  <div class="main_profile_wrapper">
    <div class="main_info_wrapper mb-5">
      <div class="info">
        <img
          class="img"
          src="../../../assets/new-theme/profile/Image wrap.png"
          alt=""
        />
      </div>
      <div class="info_data">
        <div class="wrap_one">
          <div class="main_user_img_wrapper">
            <img class="user_img" :src="image" alt="user image" />
          </div>
          <div>
            <span class="name d-block"
              >{{ userData.first_name }} {{ userData.last_name }}</span
            >
            <span class="email d-block">{{ userData.email }}</span>
          </div>
        </div>
        <!-- User Type -->
        <div class="buttons_links">
          <button class="btn_1" @click="toggleModalUserType">
            <img
              class="img"
              src="../../../assets/new-theme/profile/target-04.png"
              alt=""
            />
            <span v-if="userData.classification == 'Bridge'">{{
              localization("user type bridge")
            }}</span>
            <span v-if="userData.classification == 'Dynamic'">{{
              localization("User type dynamic")
            }}</span>
            <span v-if="userData.classification == 'Strong-Willed Learner'">{{
              localization("user type SWL")
            }}</span>
            <div class="learn_more">
              <span class="color more">Learn more</span>
              <img
                class="img"
                src="../../../assets/new-theme/profile/arrow-right.png"
                alt=""
              />
            </div>
          </button>
        </div>
      </div>
    </div>

    <!-- Start Patterns -->
    <UserPattern :userScore="userScore"></UserPattern>
    <!-- End Patterns -->

    <!-- Use Your Learning Patterns -->
    <div class="learner_patterns_wrapper hide_element_while_printing">
      <div class="titles_wrap">
        <h2 class="main_title ">Steps to personalize your profile</h2>
        <div class="print_share_buttons">
          <button class="btn_2" @click="printPlp()">
            <img
              class="img"
              src="../../../assets/new-theme/profile/printer.png"
              alt=""
            />
            <span>Print</span>
          </button>
          <button class="btn_2" @click="toggleModalShare()">
            <img
              class="img"
              src="../../../assets/new-theme/profile/share-07.png"
              alt=""
            />
            <span>Share</span>
          </button>
        </div>
      </div>
      <p class="mb-0 text">
        This is the beginning of understanding yourself and learning to take
        responsibility for using your Patterns with intention.
      </p>
      <div class="main_cards mt-3">
        <div class="card_item">
          <div class="mb-3">
            <img
              class="img"
              src="../../../assets/new-theme/profile/check-2.png"
              alt=""
            />
          </div>
          <div class="mb-4">
            <span class="title mb-2 d-block">
              Select phrases that describe you
            </span>
            <p class="mb-0 description">
              Read the statement under each learning pattern carefully and
              select phrases that perfectly describe you.
            </p>
          </div>
        </div>
        <div class="card_item">
          <div class="mb-3">
            <img
              class="img"
              src="../../../assets/new-theme/profile/plus-back.png"
              alt=""
            />
          </div>
          <div class="mb-4">
            <span class="title mb-2 d-block">
              Add your own statement
            </span>
            <p class="mb-0 description">
              If you want to describe yourself in a more accurate statement, add
              your own personalized statement.
            </p>
          </div>
        </div>
        <div class="card_item">
          <div class="mb-3">
            <img
              class="img"
              src="../../../assets/new-theme/profile/share-2.png"
              alt=""
            />
          </div>
          <div class="mb-4">
            <span class="title mb-2 d-block">
              Print or share it with it with others
            </span>
            <p class="mb-0 description">
              Print or share it with your teacher, instructor, classmates,
              colleagues, or even for a job inerview.
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- Use Your Learning Patterns -->

    <!-- Start Personalize your profile & Collapse Items -->
    <div class="main_profile_personalize_and_collapse">
      <h2 class="main_title hide_element_while_printing">
        Personalize your profile
      </h2>
      <p class="mb-0 description hide_element_while_printing">
        Your choices provide insights about how you learn, work, and interact
        with others.
      </p>

      <el-collapse
        class="mt-3 customization_for_collapse"
        v-model="panel"
        multiple
        v-if="userAttributes && isAuthEqualUser"
      >
        <div
          class="main_child "
          v-for="(attribute, i) in userAttributes"
          :key="i"
          :style="`border : 2px solid ${getProgressColor(i)}`"
        >
          <!-- Start Collapse Title -->
          <el-collapse-item
            :name="`${i}`"
            class=""
            :class="{ break_section: i == 'Precision' }"
          >
            <template #title>
              <div class="custom_header_of_collapse">
                <img
                  src="@/assets/new-theme/attributes-images/sequence.png"
                  alt="sequence"
                  v-if="i == 'Sequence'"
                />
                <img
                  src="@/assets/new-theme/attributes-images/precision.png"
                  alt="precision"
                  v-if="i == 'Precision'"
                />
                <img
                  src="@/assets/new-theme/attributes-images/technical_reasoning.png"
                  alt="technical_reasoning"
                  v-if="i == 'Technical Reasoning'"
                />
                <img
                  src="@/assets/new-theme/attributes-images/confluence.png"
                  alt="confluence"
                  v-if="i == 'Confluence'"
                />
                <span class="title_word">{{ i }} - {{ patternKey(i) }}</span>
              </div>
            </template>
            <!-- End Collapse Title -->
            <!-- Start Checkboxes -->
            <div class="p-3">
              <el-collapse class="collapse_checkboxes">
                <div
                  class="checkboxes_wrapper"
                  v-for="(item, index) in attribute"
                  :key="index"
                >
                  <el-checkbox-group class="" v-model="selected" v-if="item">
                    <el-checkbox
                      :label="item.id"
                      @change="updateSelectedAttributes"
                      :class="{
                        'd-none': item.title < 1
                      }"
                    >
                      {{ item.title }}
                    </el-checkbox>
                  </el-checkbox-group>
                  <audio controls id="audio" class="d-none">
                    <source id="audio-source" type="audio/mp3" />
                  </audio>
                  <span
                    class="span_img_wrapper"
                    role="button"
                    @click="text_speech_audio(item.title)"
                  >
                    <img
                      src="../../../assets/new-theme/attributes-images/volume.png"
                      alt="volume"
                    />
                  </span>
                </div>
                <div
                  class="your_own_statement hide_element_while_printing"
                  @click="toggleModalAddStatement(i, attribute)"
                >
                  <img
                    role="button"
                    src="@/assets/new-theme/profile/plus.png"
                    alt="plus"
                  />
                  <span role="button">Add your own personalized statement</span>
                </div>
              </el-collapse>
            </div>
            <!-- End Checkboxes  -->
          </el-collapse-item>
        </div>
      </el-collapse>
    </div>
    <!-- End Personalize your profile & Collapse Items -->

    <!-- Start How to use your Personal Learning Profile (PLP)? -->
    <div class="how_to_use hide_element_while_printing">
      <h2 class="main_title ">
        How to use your Personal Learning Profile (PLP)?
      </h2>
      <p class="mb-0 description">
        Print or share it with your teacher, instructor, classmates, colleagues,
        or even for a job inerview.
      </p>

      <div class="print_share_buttons mt-4">
        <button class="btn_2" @click="printPlp()">
          <img
            class="img"
            src="../../../assets/new-theme/profile/printer.png"
            alt=""
          />
          <span>Print your PLP</span>
        </button>
        <button class="btn_2" @click="toggleModalShare()">
          <img
            class="img"
            src="../../../assets/new-theme/profile/share-07.png"
            alt=""
          />
          <span>Share your PLP</span>
        </button>
      </div>
    </div>
    <!-- End How to use your Personal Learning Profile (PLP)? -->

    <!-- Start Modal User Type -->
    <el-dialog
      :visible.sync="showHideModalUserType"
      top="4vh"
      width="40%"
      :show-close="false"
      class="user_modal"
    >
      <div class="user_modal_type w-100">
        <img
          class="img"
          src="../../../assets/new-theme/profile/target-04.png"
          alt=""
        />

        <div>
          <h4 class="modal_title">
            <span v-if="userData.classification == 'Bridge'">{{
              localization("user type bridge")
            }}</span>
            <span v-if="userData.classification == 'Dynamic'">{{
              localization("User type dynamic")
            }}</span>
            <span v-if="userData.classification == 'Strong-Willed Learner'">{{
              localization("user type SWL")
            }}</span>
          </h4>
        </div>
        <div class="description_type">
          <p class="text_type" v-if="userData.classification == 'Bridge'">
            {{ localization("bridge tool tip") }}
          </p>
          <p class="text_type" v-if="userData.classification == 'Dynamic'">
            {{ localization("dynamic tool tip") }}
          </p>
          <p
            class="text_type"
            v-if="userData.classification == 'Strong-Willed Learner'"
          >
            {{ localization("swl to tip") }}
          </p>
        </div>
        <!-- <div class="learn_more">
          <span class="color more" role="button">Learn more</span>
          <img
            role="button"
            class="img"
            src="../../../assets/new-theme/profile/arrow-right.png"
            alt=""
          />
        </div> -->
      </div>
    </el-dialog>
    <!-- End Modal User Type -->

    <!--  Start Add new Statement -->
    <el-dialog
      :visible.sync="showHideModalAddStatement"
      top="4vh"
      width="35%"
      :show-close="false"
      class="user_modal statement_modal"
    >
      <div class="user_modal_type w-100">
        <img
          class="img"
          src="../../../assets/new-theme/profile/target-04.png"
          alt=""
        />

        <div>
          <h4 class="modal_title mb-0">
            Add new statement - {{ statementName }}
          </h4>
        </div>
        <div class="description_type">
          <p class="text_type mb-0">
            If you wish to add a more personalized statement to your profile,
            write it below.
          </p>
        </div>
        <div class="mt-3">
          <p class="desc2">
            The new statement
          </p>
          <textarea
            class="text_area"
            placeholder="Add your new statement here..."
            v-model="payload.title"
          ></textarea>
        </div>
        <div class="lci_modal_buttons mt-4">
          <button
            @click="toggleModalAddStatement()"
            class="lci_btn_modal_cancel "
          >
            Cancel
          </button>
          <button
            @click="addCustomAttribute()"
            class="lci_btn"
            :class="{ save: payload.title }"
            :disabled="!payload.title"
          >
            Confirm
          </button>
        </div>
      </div>
    </el-dialog>
    <!--  End Add new Statement -->

    <!--  Start Share -->
    <el-dialog
      :visible.sync="showHideModalShare"
      top="4vh"
      width="35%"
      :show-close="false"
      class="user_modal statement_modal"
    >
      <div class="user_modal_type w-100">
        <img
          class="img"
          src="../../../assets/new-theme/profile/share-07.png"
          alt=""
        />

        <div>
          <h4 class="modal_title mb-0">
            Share your profile
          </h4>
        </div>
        <div class="description_type">
          <p class="text_type mb-0">
            Share your personalized profile and learning patterns score with
            others to let them know how you learn, work, and interact with
            others.
          </p>
        </div>

        <div>
          <h4 class="modal_title mb-1">
            Share link
          </h4>
          <div class="copy_wrapper">
            <input class="input_link" type="text" value="www.plc.com/test" />
            <span class="span_wrap">
              <img
                class="img"
                src="../../../assets/new-theme/icons/copy.png"
                alt=""
              />
              Copy
            </span>
          </div>

          <div class="form_share"></div>
        </div>

        <div class="lci_modal_buttons mt-4">
          <button @click="toggleModalShare()" class="lci_btn_modal_cancel ">
            Cancel
          </button>
          <button @click="toggleModalShare()" class="lci_btn">
            Share
          </button>
        </div>
      </div>
    </el-dialog>
    <!--  End Share -->
  </div>
</template>

<script>
import Swal from "sweetalert2";
export default {
  name: "ProfileView",
  data() {
    return {
      showHideModalUserType: false,
      panel: [`Precision`, `Sequence`, "Technical Reasoning", "Confluence"],
      selected: [],
      showHideModalAddStatement: false,
      statementName: "",
      payload: {
        title: "",
        type: "",
        pattern: ""
      },
      showHideModalShare: false
    };
  },
  computed: {
    mainUserId() {
      return this.$route.params.id ? this.$route.params.id : this.auth().id;
    },
    userScore() {
      return this.$store.getters["user/score"];
    },
    userAttributes() {
      return this.$store.getters["user/attrs"];
    },
    isAuthEqualUser() {
      return this.auth().id == this.mainUserId;
    },
    userData() {
      return this.$store.state.user.user;
    },
    image() {
      if (this.userData.image) {
        return `${process.env.VUE_APP_BASE_URL}/users/photo/${this.userData.image}`;
      }
      return "/static/img/lml-swerl.jpg";
    }
  },
  methods: {
    // Get User Data And Set User Score
    getMainUserData() {
      this.$store.dispatch("user/user", this.mainUserId).then(() => {
        // Set the Selected User attribute
        this.selected = this.userData.selected_attributes;
      });
    },
    getProgressColor(key) {
      switch (key) {
        case "Precision":
          return "#3c3";
        case "Technical Reasoning":
          return "#f93";
        case "Confluence":
          return "#c36";
        case "Sequence":
          return "#39c";
      }
    },

    // Toggle Modal User Type
    toggleModalUserType() {
      this.showHideModalUserType = !this.showHideModalUserType;
    },
    // Toggle Modal User Type
    toggleModalAddStatement(statementName = "", attribute = []) {
      this.showHideModalAddStatement = !this.showHideModalAddStatement;
      this.statementName = statementName;
      this.payload.pattern = statementName;
      if (attribute.length > 0) {
        this.payload.type = attribute[0].type;
      }
    },
    updateSelectedAttributes() {
      this.$store
        .dispatch("user/update_attribute", {
          id: this.mainUserId,
          query: { selected_attributes: this.selected }
        })
        .then(() => {
          this.getMainUserData();
        });
    },
    printPlp() {
      window.print();
    },
    text_speech_audio(any_text) {
      this.$store
        .dispatch("user/get_text_to_speech_audio", { text: any_text })
        .then(res => {
          let audioBlob = new Blob([res], { type: "audio/mpeg" });
          let audioURL = URL.createObjectURL(audioBlob);
          let audio = document.getElementById("audio");
          var source = document.getElementById("audio-source");
          source.src = audioURL;
          audio.load();
          audio.play();
        });
    },
    addCustomAttribute() {
      this.showHideModalAddStatement = !this.showHideModalAddStatement;
      this.$store
        .dispatch("user/add_custom_attribute", this.payload)
        .then(() => {
          this.getMainUserData();
          this.payload.title = "";
          this.payload.type = "";
          Swal.fire({
            icon: "success",
            text: `${this.localization("Done")}`,
            confirmButtonText: `${this.localization("Ok")}`,
            type: "success",
            confirmButtonClass: "btn btn-success ",
            buttonsStyling: false
          });
        });
    },
    // Toggle Modal User Type
    toggleModalShare() {
      this.showHideModalShare = !this.showHideModalShare;
    },
    // Return pattern key in collapse
    patternKey(name) {
      if (name == "Sequence") {
        // Start Pattern range
        if (this.userScore?.sequence >= 25 && this.userScore.sequence <= 35) {
          return "Use First";
        } else if (
          this.userScore?.sequence >= 7 &&
          this.userScore.sequence <= 17
        ) {
          return "Avoid";
        } else if (
          this.userScore?.sequence >= 18 &&
          this.userScore.sequence <= 24
        ) {
          return "As Needed";
        }
        // End Pattern range
      }
      if (name == "Precision") {
        // Start Pattern range
        if (this.userScore?.precision >= 25 && this.userScore.precision <= 35) {
          return "Use First";
        } else if (
          this.userScore?.precision >= 7 &&
          this.userScore.precision <= 17
        ) {
          return "Avoid";
        } else if (
          this.userScore?.precision >= 18 &&
          this.userScore.precision <= 24
        ) {
          return "As Needed";
        }
        // End Pattern range
      }
      if (name == "Technical Reasoning") {
        // Start Pattern range
        if (
          this.userScore?.technical_reasoning >= 25 &&
          this.userScore.technical_reasoning <= 35
        ) {
          return "Use First";
        } else if (
          this.userScore?.technical_reasoning >= 7 &&
          this.userScore.technical_reasoning <= 17
        ) {
          return "Avoid";
        } else if (
          this.userScore?.technical_reasoning >= 18 &&
          this.userScore.technical_reasoning <= 24
        ) {
          return "As Needed";
        }
        // End Pattern range
      }
      if (name == "Confluence") {
        // Start Pattern range
        if (
          this.userScore?.confluence >= 25 &&
          this.userScore.confluence <= 35
        ) {
          return "Use First";
        } else if (
          this.userScore?.confluence >= 7 &&
          this.userScore.confluence <= 17
        ) {
          return "Avoid";
        } else if (
          this.userScore?.confluence >= 18 &&
          this.userScore.confluence <= 24
        ) {
          return "As Needed";
        }
        // End Pattern range
      }
    }
  },
  mounted() {
    this.getMainUserData();
  }
};
</script>

<style lang="scss" scoped>
@media print {
  * {
    -webkit-print-color-adjust: exact !important; /* Chrome, Safari, Edge */
    color-adjust: exact !important; /*Firefox*/
  }
}
.main_profile_wrapper {
  background: #fff;
  width: 100%;
  max-width: 100%;
  padding: 0 30px 50px;

  .main_title {
    color: #101828;
    font-weight: 600;
    font-size: 20px;
    &.white_text {
      color: #fff;
    }
  }

  // Use Your Learning Patterns
  .learner_patterns_wrapper {
    margin-top: 40px;
    .titles_wrap {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .print_share_buttons {
        display: flex;
        align-items: center;
        gap: 10px;
      }
    }
    .btn_2 {
      border: 1px solid transparent;
      border-radius: 8px;
      color: #6941c6;
      font-size: 14px;
      height: 41px;
      padding: 10px 16px;
      display: flex;
      align-items: center;
      font-weight: 500;
      gap: 10px;
      background: #fff;
    }
    .text {
      color: #667085;
      font-weight: 400;
      font-size: 16px;
    }
    .main_cards {
      display: flex;
      justify-content: space-between;
      gap: 4%;
      .card_item {
        border-radius: 8px;
        border: 1px solid #eaecf0;
        padding: 24px;
        width: calc(96% / 3);

        .title {
          color: #101828;
          font-weight: 600;
          font-size: 18px;
        }
        .description {
          color: #667085;
          font-weight: 400;
          font-size: 16px;
        }
        .lci_btn {
          background-color: #f2f4f7;
          color: #6941c6;
          border-radius: 8px;
          padding: 12px 20px;
          border: 1px solid #eaecf0;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 8px;
          width: 100%;
          font-weight: 500;
          font-size: 16px;
        }
      }
    }
  }
  // Start Personalize your profile & Collapse Items
  .main_profile_personalize_and_collapse {
    .description {
      color: #667085;
      font-weight: 400;
      font-size: 16px;
    }
    .customization_for_collapse {
      .main_child {
        padding: 20px;
        // border: 2px solid #ccc;
        margin-bottom: 30px;
        border-radius: 16px;
      }
      .el-collapse-item__header {
        padding: 2.5rem 0;
        @media print {
          // padding: 1.5rem 0;
        }
      }

      .custom_header_of_collapse {
        width: 100%;
        border-bottom: 1px solid #eaecf0;
        .title_word {
          margin: 0 10px;
          color: #344054;
        }
      }
      .collapse_checkboxes {
        .checkboxes_wrapper {
          margin-bottom: 15px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .span_img_wrapper {
            border: 1px solid #d0d5dd;
            border-radius: 6px;
            height: 32px;
            width: 32px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
      .your_own_statement {
        border-top: 1px solid #eaecf0;
        padding: 24px 0;
        display: flex;
        align-items: center;
        gap: 10px;
        font-weight: 500;
        color: #6941c6;
      }
      // .el-collapse-item .el-collapse-item__content {
      //   box-shadow: none !important;
      //   border: none !important;
      // }
    }
  }
  // End Personalize your profile & Collapse Items

  // Start How to use your Personal Learning Profile (PLP)?
  .how_to_use {
    .print_share_buttons {
      display: flex;
      align-items: center;
      gap: 2%;
    }
    .btn_2 {
      border: 1px solid #6941c6;
      border-radius: 8px;
      color: #6941c6;
      font-size: 14px;
      height: 41px;
      padding: 10px 16px;
      display: flex;
      align-items: center;
      font-weight: 500;
      gap: 10px;
      background: #fff;
      width: calc(98% / 2);
      justify-content: center;
    }
  }
  // End How to use your Personal Learning Profile (PLP)?
  .color {
    color: #6941c6;
  }

  .lci_modal_buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3%;
    .lci_btn,
    .lci_btn_modal_cancel {
      background-color: #fff;
      color: #667085;
      border-radius: 8px;
      padding: 12px 20px;
      border: 1px solid #eaecf0;
      width: calc(97% / 2);
    }
    .lci_btn {
      background-color: #f2f4f7;
      &.save {
        background-color: #6941c6;
        color: #fff;
      }
    }
  }
  .statement_modal {
    .desc2 {
      color: #344054;
      font-weight: 500;
      font-size: 16px;
    }
    .text_area {
      border: 1px solid #d0d5dd;
      height: 92px;
      width: 100%;
      resize: none;
      border-radius: 8px;
      color: #101828;
      padding: 15px;
      outline: none;
      word-break: break-word;
    }
  }
}

.user_modal {
  padding: 0;
  .el-dialog__header {
    display: none !important;
  }
  .user_modal_type {
    .modal_title {
      color: #101828;
      margin-top: 20px;
      margin-bottom: 10px;
      font-weight: 500;
      font-size: 18px;
    }
    .description_type {
      .text_type {
        color: #667085;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        word-break: break-word;
      }
    }

    .copy_wrapper {
      border: 1px solid #d0d5dd;
      display: flex;
      align-items: center;
      justify-content: space-between;
      overflow: hidden;
      border-radius: 8px;
      .input_link {
        border: none;
        outline: none;
        padding: 0 10px;
        height: 44px;
        flex-grow: 1;
      }
      .span_wrap {
        padding: 0 10px;
        height: 44px;
        border-left: 1px solid #d0d5dd;
        width: 107px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
      }
    }

    .form_share {
    }
  }
}
</style>

<style lang="scss">
.user_modal {
  padding: 0;
  .el-dialog__header {
    display: none !important;
  }
}

.main_profile_personalize_and_collapse {
  .el-collapse {
    border: none !important;
  }
  .el-collapse-item__content {
    box-shadow: none !important;
    border: none !important;

    // @media print {
    //   padding-bottom: 0;
    // }
  }
  .el-collapse-item__wrap {
    border-bottom: none !important;
  }
  .el-collapse-item__content {
    padding-bottom: 0;
    font-size: 16px;
    line-height: inherit;
    color: #344054 !important;
  }
  .el-checkbox__inner {
    border: 1px solid #d0d5dd;
    border-radius: 6px;
    height: 20px;
    width: 20px;
  }
  .el-checkbox {
    align-items: flex-start;
    display: flex;
  }
  .el-checkbox__label,
  .el-checkbox is-checked {
    color: #344054 !important;
    font-weight: 400;
    font-size: 16px;

    // max-width: 98%;
    word-break: break-word !important;
    white-space: pre-wrap;
    // word-wrap: break-word;
    white-space: normal;
  }
  .el-checkbox__input.is-checked .el-checkbox__inner,
  .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: #6941c6;
    border-color: #6941c6;
  }
  .el-checkbox__inner::after {
    left: 7px;
    top: 4px;
    border-width: 2px;
  }
}
</style>
